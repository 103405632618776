<template>
  <el-dialog
    width="480px"
    class="address-add"
    title="收货信息"
    label-position="right"
    :visible.sync="params.visible"
  >
    <el-form size="mini" ref="form" :model="form" :rules="rules" label-width="100px">
      <el-form-item label="收货人:" prop="consignee">
        <el-input v-model="form.consignee" placeholder="请填写收货人姓名"></el-input>
      </el-form-item>
      <el-form-item label="联系电话:" prop="mobile">
        <el-input v-model="form.mobile" placeholder="请填写手机号码" maxlength="11"></el-input>
      </el-form-item>
      <el-form-item label="收货地址:" prop="region">
        <CitySelect
          @change="changeCity"
          :pid="form.areaId0"
          :cid="form.areaId1"
          :rid="form.areaId2"
        ></CitySelect>
      </el-form-item>
      <el-form-item label="详情地址:" prop="address">
        <el-input v-model="form.address" placeholder="请输入街道, 门牌号"></el-input>
      </el-form-item>
      <el-form-item prop="isDefault">
        <el-checkbox class="default" size="mini" v-model="form.isDefault">设为默认地址</el-checkbox>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer flex flex-center">
      <el-button type="primary" @click="onConfirm">保存</el-button>
    </span>
  </el-dialog>
</template>
<script>
import CitySelect from '@/components/common/citySelect'
export default {
  components: {
    CitySelect
  },
  data() {
    return {
      rules: {
        consignee: [
          {
            required: true,
            message: '请填写收货人姓名',
            trigger: 'blur'
          }
        ],
        mobile: [
          { required: true, message: '请输入收货人联系电话', trigger: 'blur' },
          { required: true, message: '收货人联系电话格式输入不正确', trigger: 'blur', pattern: /^[1]([3-9])[0-9]{9}$/ }
        ],
        region: [
          {
            validator: (rule, value, callback) => {
              if (value[0] === '') {
                callback(new Error('请选择省'))
              } else if (value[1] === '') {
                callback(new Error('请选择市'))
              } else if (value[2] === '') {
                callback(new Error('请选择区域'))
              } else {
                callback()
              }
            },
            trigger: 'blur'
          }
        ],
        address: [
          {
            required: true,
            message: '请填写详细地址',
            trigger: 'blur'
          }
        ]
      },
      form: {
        consignee: '',
        mobile: '',
        areaId0: '',
        areaId1: '',
        areaId2: '',
        address: '',
        isDefault: false,
        region: ['', '', '']
      }
    }
  },
  props: {
    params: {},
    data: {}
  },
  watch: {
    'params.isEdit': {
      handler(val) {
        if (val) {
          this.form = this.params.item
          const {areaId0, areaId1, areaId2} = this.form
          this.form['region'] = [areaId0, areaId1, areaId2]
        }
      },
      immediate: true
    }
  },
  methods: {
    reset() {
      this.$nextTick(() => {
        const { form } = this.$refs
        form && form.resetFields()
      })
    },
    async onConfirm() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          delete this.form.region
          delete this.form.selected
          this.$http.post('userConsignee/addSite', this.form).then(res => {
            const { code } = res.data
            if (code * 1 === 0) {
              this.reset()
              this.params.confirm()
              this.$emit('init')
            }
          })
        } else {
          return false
        }
      })
    },
    changeCity(e) {
      const { province, city, region } = e
      this.form.areaId0 = province
      this.form.areaId1 = city
      this.form.areaId2 = region
      this.form.region = [province, city, region]
    }
  }
}
</script>
<style lang="scss" scoped>
.address-add {
  /deep/ .el-dialog__body {
    padding: 18px 20px;
    .el-form-item__content{
      margin-left: 0 !important;
    }
    .el-input--mini{
      .el-input__inner{
        width: 100%;
      }
    }
  }
  .el-input {
    width: 220px;
  }
  .default {
    margin-left: 30px;
    font-weight: normal;
  }

  /deep/ .el-form-item__label::before{
    content: '*';
    color: #F56C6C;
    margin-right: 4px;
  }
}



</style>
