<template>
  <div class="address-item" :class="cls" @click="onSelected">
    <div class="address-user">
      <span class="address-user-name">{{item.consignee}}</span>
      <span class="address-user-edit" v-if="types!=1" @click.stop="$emit('update', item)">修改</span>
      <span class="address-user-del" v-if="types!=1" @click.stop="delAddress(item.id)">删除</span>
      <span class="address-user-set" @click.stop="setDefault(item.isDefault,item.id)">{{item.isDefault? "默认地址": "设置默认"}}</span>
    </div>
    <div class="address-phone">{{item.mobile}}</div>
    <div class="address-info">{{item.address | removeComma}}</div>
  </div>
</template>
<script>

export default {
  data() {
    return {
      item: this.data
    }
  },
  props: {
    data: {
      type: Object,
      default() {
        return {}
      }
    },
    types: String,
    selected: Boolean
  },
  watch: {
    data: {
      handler(val) {
        this.item = val
        const {isDefault} = this.item
        if (isDefault) this.$emit('selected', true)
      },
      immediate: true
    }
  },
  computed: {
    cls() {
      if (this.item.isDisable) {
        return 'address-item--disable'
      }
      if (this.selected) {
        return 'address-item--selected'
      }
      if (this.item.isDefult) {
        return 'address-item--is-default'
      }
      return ''
    }
  },
  methods: {
    onSelected() {
      this.$emit('selected', !this.selected)
    },
    delAddress() {
      this.$emit('delete', this.item.id)
    },
    setDefault(isDefault,id) {
      if (isDefault) return
      else this.$emit('setDefault', id);

    }
  }
}
</script>
<style lang="scss">
@import '../../../assets/scss/common.scss';
@mixin icon($m) {
  $url: '../../../assets/image/' + $m;
  @include bgImage($url);
}
%selected {
  // border: 1px solid $color168;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    width: 38px;
    height: 38px;
    background: url('./img/gouxuan.png') no-repeat;
    background-size: 100% 100%;
  }
}

.address-item {
  flex: 0 0 24%;
  margin-right: 1%;
  height: 150px;
  padding: 10px;
  box-sizing: border-box;
  margin-bottom: 10px;
  cursor: pointer;
  @include icon('bg-address.png');
  &:hover {
    border-color: $color168;
  }
  &--selected {
    @extend %selected;
  }
  // &--is-default {
  //   @extend %selected;
  //   &::before {
  //     top: 0;
  //     background: url('./img/moren.png') no-repeat;
  //   }
  // }

  // 不在服务范围
  &--disable {
    border: 1px solid rgba(234, 234, 234, 1);
    background: rgba(245, 245, 245, 1);
    opacity: 0.5;
    pointer-events: none;
  }

  .address-user {
    padding: 10px;
    font-size: 12px;
    color: #333;
    span {
      float: left;
    }
    .address-user-name {
      width: 40%;
      font-size: 16px;
    }
    .address-user-edit {
      width: 15%;
      &:hover {
        color: $color168;
      }
    }
    .address-user-del {
      width: 15%;
      &:hover {
        color: red;
      }
    }
    .address-user-set {
      display: none;
      color:#d82229 ;
      // width: 30%;
      margin-left: 66px;
    }
  }
  .address-phone {
    width: 220px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-top: 20px;
    padding: 10px;
    color: #666;
    font-size: 16px;
  }
  .address-info {
    color: $color999;
    font-size: 14px;
    padding: 5px 10px;
    width: 220px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  &.address-item_no {
    border: 1px solid #ebebeb;
  }
}
</style>

